import angular from "angular";

facebookLike.$inject = ["$window", "$sce"];

/**
 *
 * facebook-like button
 *
 * <span facebook-like url="https://www..."></span>
 *
 * url is optional
 * else looks for link[rel=canonical]
 * else uses document.location
 */
export function facebookLike($window, $sce) {
  return {
    template:
      '<iframe ng-src="{{::url}}" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:200px; height:27px;" allowTransparency="true"></iframe>',
    replace: true,
    scope: {},
    link: function ($scope, element, attrs) {
      const url =
          attrs.url ||
          angular.element("link[rel=canonical]").attr("href") ||
          $window.location.href,
        fburl =
          "https://www.facebook.com/plugins/like.php?href=" +
          url +
          "&amp;layout=button_count&amp;show_faces=false&amp;width=95&amp;action=like&amp;colorscheme=light&amp;height=21";
      $scope.url = $sce.trustAsResourceUrl(fburl);
    },
  };
}
